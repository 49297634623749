.page-background {
    background: url(/img/bg.jpg) 50% 0 no-repeat;
    width: 100%;
    background-color:#616d01;
}

.product-image-preview {
    width:150px;
    height:100px
}

.product-price {
    font-size:15px;
    font-weight:bolder;
}

.ui.menu {
    margin-top: 0px;
}

.fullWidth {
    flex: 1;
}

.disabledLink {
    pointer-events: none;
    cursor: not-allowed;
}